import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import React from 'react';
import styled from 'styled-components';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export type TitleSizes = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export type SubTitleFontStyle = 'primary' | 'secondary';

export type TextAlignment = 'left' | 'center' | 'right';

export interface TitleProps {
    value?: string;
    subTitle?: string;
    size?: TitleSizes;
    accent?: boolean;
    subTitleFontStyle?: SubTitleFontStyle;
    marginBottom?: number;
    marginTop?: number;
    alignment?: TextAlignment;
    link?: string;
    linkText?: string;
}

const AccentWrapper = styled.div<{ alignment?: TextAlignment }>`
    width: 10vw;
    margin-top: 1.5rem;
    margin-left: ${props => (props.alignment === 'center' || !props.alignment ? 'auto' : undefined)};
    margin-right: auto;
    background-color: #faa338;
    height: 4px;
    border-radius: 3px;
    margin-bottom: 30px;
`;

const SubTitleWrapper = styled.div<{ justifyContent?: string; alignItems?: string }>`
    display: flex;
    width: 100%;
    justify-content: ${props => props.justifyContent};
    align-items: ${props => props.alignItems};
`;

const HeaderLink = styled.a`
    color: var(--primaryColor);
    text-decoration: none;
    font-size: 0.8em;
    font-family: 'Courier New';
`;

const Link = ({ link, linkText }: { link: string; linkText?: string }) => {
    return (
        <HeaderLink href={link} target="_blank">
            {linkText || 'LINK'} <FontAwesomeIcon icon={faLink} />
        </HeaderLink>
    );
};

export const Header = ({
    value,
    size,
    marginBottom,
    marginTop,
    link,
    linkText,
}: {
    value?: string;
    size?: TitleSizes;
    marginBottom?: number;
    marginTop?: number;
    link?: string;
    linkText?: string;
}) => {
    const style = {
        marginBottom: marginBottom || marginBottom === 0 ? marginBottom + 'rem' : undefined,
        marginTop: marginTop || marginTop === 0 ? marginTop + 'rem' : undefined,
    };
    switch (size) {
        case 'h6':
            return (
                <h6 style={style}>
                    {value} {link && <Link link={link} linkText={linkText} />}
                </h6>
            );
        case 'h5':
            return (
                <h5 style={style}>
                    {value} {link && <Link link={link} linkText={linkText} />}
                </h5>
            );
        case 'h4':
            return (
                <h4 style={style}>
                    {value} {link && <Link link={link} linkText={linkText} />}
                </h4>
            );
        case 'h3':
            return (
                <h3 style={style}>
                    {value} {link && <Link link={link} linkText={linkText} />}
                </h3>
            );
        case 'h2':
            return (
                <h2 style={style}>
                    {value} {link && <Link link={link} linkText={linkText} />}
                </h2>
            );
        default:
            return (
                <h1 style={style}>
                    {value} {link && <Link link={link} linkText={linkText} />}
                </h1>
            );
    }
};

const Accent = ({ show, value, alignment }: { show: boolean; value?: string; alignment?: TextAlignment }) => {
    if (show && value) return <AccentWrapper alignment={alignment}>&nbsp;</AccentWrapper>;
    else return <></>;
};

const SubTitleContent = styled.div<{ marginBottom?: number; alignment?: string; font?: string; mobile?: boolean }>`
    display: flex;
    width: 100%;
    margin-bottom: ${props => (props.marginBottom && props.marginBottom === 0 ? '1.5rem' : undefined)};
    flex-basis: ${props => (props.alignment === 'center' && !props.mobile ? '60%' : '100%')};
    font-style: ${props => (props.font === 'secondary' ? 'italic' : '')};
    font-size: ${props => (props.font === 'secondary' ? '15px' : '')};
    justify-content: ${props => (props.alignment === 'center' ? 'center' : undefined)};
`;

const SubTitle = ({
    value,
    fontStyle,
    marginBottom,
    alignment,
}: {
    value?: string;
    fontStyle: SubTitleFontStyle;
    marginBottom?: number;
    alignment?: TextAlignment;
}) => {
    const breakpoints = useBreakpoint();
    const mobile = breakpoints.xs || breakpoints.sm;
    return (
        <SubTitleWrapper justifyContent={alignment} alignItems={alignment}>
            <SubTitleContent marginBottom={marginBottom} alignment={alignment} font={fontStyle} mobile={mobile}>
                {value}
            </SubTitleContent>
        </SubTitleWrapper>
    );
};

const TitleWrapper = styled.div<{ justifyContent?: string; textAlign?: string }>`
    justify-content: ${props => props.justifyContent};
    text-align: ${props => props.textAlign};

    h1 {
        color: var(--textNormal);
    }
`;

export const Title = ({
    value,
    size,
    accent = true,
    subTitle,
    subTitleFontStyle = 'primary',
    marginBottom,
    marginTop,
    alignment = 'center',
    link,
    linkText,
}: TitleProps) => {
    return (
        <TitleWrapper textAlign={alignment}>
            <Accent show={accent} value={value} alignment={alignment} />
            <Header
                value={value}
                size={size}
                marginBottom={marginBottom}
                marginTop={marginTop}
                link={link}
                linkText={linkText}
            />
            <SubTitle
                value={subTitle}
                fontStyle={subTitleFontStyle}
                marginBottom={marginBottom}
                alignment={alignment}
            />
        </TitleWrapper>
    );
};

export default Title;
